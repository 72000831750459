<template>
  <div>
    <v-breadcrumbs class="pa-0 mb-4" :items="[{ text: $t('main.back') }]">
      <template #item="{ item }">
        <v-breadcrumbs-item class="global-cursor-pointer" @click="backToRoute()">
          <v-icon color="black" size="15">
            $arrow-left
          </v-icon>
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-card elevation="0" :loading="isLoading">
      <v-card-title class="white--text black pa-2 pl-6">
        <span class="text-subtitle-1 text-sm-h6 font-weight-medium mr-2 ">{{ $t('marketplace.offer') }}</span>
        <span class="text-subtitle-2 font-weight-regular">(ID {{ flatDealId }})</span>
        <c-btn
          :label="$t('marketplace.download')"
          class="ml-auto"
          :loading="isLoading"
          small
          :to="{ name: routeNames.FLAT_DEAL_OFFER, query: dealTerm }"
          color="primary"
        />
      </v-card-title>
      <v-card-text class="grid-container pt-4">
        <logo-card class="logo-card" :logo-src="flatDeal.site.logo.src" />
        <parameter-card :title="$t('marketplace.domain')" title-icon="$earth-search" class="domain-card">
          <a
            :href="`https://${flatDeal.site.domain}`"
            target="_blank"
            class="d-block text-center text-caption text-lg-body-1 info--text font-weight-bold"
          >
            {{ flatDeal.site.domain }}
          </a>
        </parameter-card>
        <parameter-card
          :title="$t('marketplace.site_rank')"
          title-icon="$arrow-up-down"
          :body="`#${flatDeal.site.siteReport.similarwebGlobalRank}`"
          class="site-rank-card"
        />
        <parameter-card
          :title="`${$t('marketplace.top_5_countries')}, %`"
          title-icon="$location"
          class="top-countries"
        >
          <top-countries-card :site="flatDeal.site" />
        </parameter-card>
        <parameter-card
          :title="$t('marketplace.total_visits')"
          title-icon="$human-meeting"
          class="visits-card"
          :body="`${toFixedByDefault(flatDeal.site.siteReport.similarwebTotalVisits / 10 ** 6)}m`"
        />
        <parameter-card
          :title="$t('marketplace.page_views')"
          title-icon="$eye"
          class="impressions-card"
          :body="`${toFixedByDefault(flatDeal.site.siteReport.similarWebPageViews / 10 ** 6)}m`"
        />
        <parameter-card
          :title="$t('marketplace.vertical_rank')"
          title-icon="$equalizer"
          class="vertical-card"
          :body="`${flatDeal.site.siteReport.similarwebCategory}/${flatDeal.site.siteReport.similarwebCategoryRank}`"
        />
        <parameter-card
          :title="`${$t('marketplace.channel_overviews')}, %`"
          title-icon="$channel"
          class="channel-card"
        >
          <channel-overviews-card :site="flatDeal.site" />
        </parameter-card>
        <parameter-card
          :title="`${$t('marketplace.device_type')}, %`"
          title-icon="$monitor-mobile"
          class="device-card"
        >
          <device-type-card :site="flatDeal.site" />
        </parameter-card>
        <parameter-card
          :title="`${$t('marketplace.gender')}, %`"
          title-icon="$yin-yang"
          class="gender-card"
        >
          <gender-card class="gender-card" :site="flatDeal.site" />
        </parameter-card>
      </v-card-text>
      <v-card-text class="mt-11">
        <div class="text-center black--text text-h6 mb-5">
          {{ flatDeal.site.domain.toUpperCase() }} {{ flatDeal.placementType.adFormat.name }} Spot
        </div>
        <v-container fluid class="px-0">
          <v-row>
            <v-col cols="12" lg="6">
              <offer-table :flat-deal="flatDeal" :exclude-header="['domain']" />
            </v-col>
            <v-col cols="12" lg="6">
              <request-card
                v-if="flatDeal.id"
                :debounced-update-price-is-loading="debouncedUpdatePriceIsLoading"
                :is-loading="updatePriceIsLoading"
                :errors="errors"
                :deal-term="dealTerm"
                :flat-deal="flatDeal"
                class="box-shadow rounded pa-4 pa-sm-6"
                @update-deal-term="updateDealTerm($event)"
                @send-request="sendGtm()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <similar-deals
      :key="flatDealId"
      class="mt-16 pt-2"
      :deal-id="flatDealId"
    />
  </div>
</template>

<script>
  import { toFixedByDefault } from '@clickadilla/components/utils/to-fixed-by-default.js'
  import debounce from 'debounce'
  import { mapGetters, mapState } from 'vuex'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import handleErrors from '@/services/handleErrors.js'
  import { flatDealsRepository } from '@/services/repository-factory.js'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import GenderCard from '@/views/FlatDeal/GenderChart.vue'
  import TopCountriesCard from '@/views/FlatDeal/TopCountriesChart.vue'
  import DeviceTypeCard from '@/views/FlatDeal/DeviceTypeChart.vue'
  import ChannelOverviewsCard from '@/views/FlatDeal/ChannelOverviewsChart.vue'
  import ParameterCard from '@/views/FlatDeal/ParameterCard.vue'
  import OfferTable from '@/components/flat-deal/OfferTable.vue'
  import RequestCard from '@/components/flat-deal/RequestCard.vue'
  import flatDealLimitTypes from '@/types/flat-deal-limit-types.js'
  import LogoCard from '@/views/FlatDeal/LogoCard.vue'
  import SimilarDeals from './SimilarDeals.vue'
  import gtmPush from '@/services/utils/gtm-push.js'
  import routeNames from '@/types/route-names.js'

  const { IMPRESSIONS, DAYS } = flatDealLimitTypes

  export default {
    name: 'FlatDeal',
    components: {
      CBtn,
      LogoCard,
      RequestCard,
      OfferTable,
      ParameterCard,
      ChannelOverviewsCard,
      DeviceTypeCard,
      TopCountriesCard,
      GenderCard,
      SimilarDeals
    },
    data() {
      return {
        isLoading: false,
        updatePriceIsLoading: false,
        flatDeal: new FlatDeal(),
        debouncedUpdatePriceIsLoading: false,
        errors: {},
        dealTerm: {
          type: IMPRESSIONS,
          value: 0
        },
        routeNames
      }
    },
    computed: {
      ...mapState('settings', ['backRouteName', 'flatDealDefaultLimitType']),
      ...mapGetters('settings', ['getCasedFlatDealDefaultValueKey']),
      flatDealId() {
        return +this.$route.params.id
      },
      block() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          return {
            container: { columns: 'repeat(14,1fr)', row: 'repeat(4,1fr)' },
            logoCard: { column: '1/3', row: '1' },
            domainCard: { column: '1/3', row: '2' },
            topCountries: { column: '3/11', row: '1/3' },
            visitsCard: { column: '11/13', row: '1' },
            impressionsCard: { column: '13/15', row: '1' },
            siteRankCard: { column: '11/13', row: '2' },
            verticalCard: { column: '13/15', row: '2' },
            channelCard: { column: '1/9', row: '3/5' },
            deviceCard: { column: '9/12', row: '3/5' },
            genderCard: { column: '12/15', row: '3/5' }
          }
        }
        if (this.$vuetify.breakpoint.md) {
          return {
            container: { columns: 'repeat(4,1fr)', row: 'repeat(7,1fr)' },
            logoCard: { column: '1', row: '1' },
            domainCard: { column: '1', row: '2' },
            topCountries: { column: '2/5', row: '1/3' },
            visitsCard: { column: '1', row: '5' },
            impressionsCard: { column: '2', row: '5' },
            siteRankCard: { column: '3', row: '5' },
            verticalCard: { column: '4', row: '5' },
            channelCard: { column: '1/5', row: '3/5' },
            deviceCard: { column: '1/3', row: '6/8' },
            genderCard: { column: '3/5', row: '6/8' }
          }
        }
        if (this.$vuetify.breakpoint.sm) {
          return {
            container: { columns: 'repeat(2,1fr)', row: 'repeat(11,1fr)' },
            logoCard: { column: '1', row: '1' },
            domainCard: { column: '2', row: '1' },
            topCountries: { column: '1/3', row: '2/4' },
            visitsCard: { column: '1', row: '6' },
            impressionsCard: { column: '2', row: '6' },
            siteRankCard: { column: '1', row: '7' },
            verticalCard: { column: '2', row: '7' },
            channelCard: { column: '1/3', row: '4/6' },
            deviceCard: { column: '1/3', row: '8/10' },
            genderCard: { column: '1/3', row: '10/12' }
          }
        }
        return {
          container: { columns: 'repeat(2,1fr)', row: 'repeat(11,1fr)' },
          logoCard: { column: '1', row: '1' },
          domainCard: { column: '2', row: '1' },
          topCountries: { column: '1/3', row: '2/4' },
          visitsCard: { column: '1', row: '6' },
          impressionsCard: { column: '2', row: '6' },
          siteRankCard: { column: '1', row: '7' },
          verticalCard: { column: '2', row: '7' },
          channelCard: { column: '1/3', row: '4/6' },
          deviceCard: { column: '1/3', row: '8/10' },
          genderCard: { column: '1/3', row: '10/12' }
        }
      }
    },
    watch: {
      flatDealId: {
        immediate: true,
        handler() {
          this.initFlatDeal()
        }
      }
    },
    methods: {
      async initFlatDeal() {
        window.scrollTo(0, 0)
        await this.fetchFlatDeal()
        this.debouncedUpdatePriceFlatDeal = debounce(this.updatePriceFlatDeal, 1500)
        if (this.$route.query.limitType && this.$route.query.value) {
          this.dealTerm = {
            type: this.$route.query.limitType,
            value: this.$route.query.value
          }
          await this.updatePriceFlatDeal()
        }
      },
      async fetchFlatDeal() {
        this.isLoading = true
        try {
          await flatDealsRepository.show(this.flatDealId)
          const response = await flatDealsRepository.show(this.flatDealId)
          this.flatDeal = new FlatDeal(response)
          this.dealTerm = {
            type: this.flatDealDefaultLimitType,
            value: this.flatDeal[this.getCasedFlatDealDefaultValueKey]
          }
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      updateDealTerm(value) {
        this.dealTerm = value
        this.debouncedUpdatePriceIsLoading = true
        this.debouncedUpdatePriceFlatDeal()
      },
      async updatePriceFlatDeal() {
        this.updatePriceIsLoading = true
        this.errors = {}
        const params = {
          limit_type: this.dealTerm.type,
          ...this.dealTerm.type === DAYS && { days: this.dealTerm.value },
          ...this.dealTerm.type === IMPRESSIONS && { total_traffic: this.dealTerm.value }
        }
        try {
          const response = await flatDealsRepository.price(this.flatDeal.id, params)
          this.flatDeal.updatePrice(response)
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.updatePriceIsLoading = false
        this.debouncedUpdatePriceIsLoading = false
      },
      async backToRoute() {
        if (this.backRouteName) {
          this.$router.go(-1)
        }
        await this.$router.push({ name: routeNames.MARKETPLACE })
      },
      sendGtm() {
        gtmPush({
          event: GTM_EVENTS.SEND_REQUEST_SPOT,
          event_category: GTM_EVENTS_CATEGORY.FEATURE
        })
      },
      toFixedByDefault
    }
  }
</script>

<style lang="scss" scoped>
  .grid-container {
    display: grid;
    grid-template-columns: v-bind('block.container.columns');
    grid-template-rows: v-bind('block.container.row');
    grid-gap: 8px;
  }
  .logo-card {
    grid-column: v-bind('block.logoCard.column');
    grid-row: v-bind('block.logoCard.row');
  }
  .domain-card {
    grid-column: v-bind('block.domainCard.column');
    grid-row: v-bind('block.domainCard.row');
  }
  .site-rank-card {
    grid-column: v-bind('block.siteRankCard.column');
    grid-row: v-bind('block.siteRankCard.row');
  }
  .top-countries{
    grid-column: v-bind('block.topCountries.column');
    grid-row: v-bind('block.topCountries.row');
  }
  .visits-card {
    grid-column: v-bind('block.visitsCard.column');
    grid-row: v-bind('block.visitsCard.row');
  }
  .impressions-card {
    grid-column: v-bind('block.impressionsCard.column');
    grid-row: v-bind('block.impressionsCard.row');
  }
  .vertical-card {
    grid-column: v-bind('block.verticalCard.column');
    grid-row: v-bind('block.verticalCard.row');
  }
  .channel-card {
    grid-column: v-bind('block.channelCard.column');
    grid-row: v-bind('block.channelCard.row');
  }
  .device-card {
    grid-column: v-bind('block.deviceCard.column');
    grid-row: v-bind('block.deviceCard.row');
  }
  .gender-card {
    grid-column: v-bind('block.genderCard.column');
    grid-row: v-bind('block.genderCard.row');
  }
  .box-shadow {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.08)!important;
  }
</style>
