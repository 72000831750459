<template>
  <v-expand-transition mode="out-in">
    <div v-if="similarFlatDeals.length && !isLoading">
      <div class="text-h6 text-center mb-3 mb-sm-6">
        {{ $t('marketplace.more_suggestions') }}
      </div>
      <div v-for="(deal, index) in similarFlatDeals" :key="index">
        <flat-deal-card
          :flat-deal="deal"
          :class="{ 'mb-5 mb-sm-3 mb-lg-4': index !== similarFlatDeals.length - 1 }"
          @send-request="sendGtm(GTM_EVENTS.SEND_REQUEST_SIMILAR)"
          @flat-buy-click="sendGtm(GTM_EVENTS.BUY_CARD_SIMILAR)"
        />
      </div>
    </div>
  </v-expand-transition>
</template>

<script>
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import FlatDealCard from '@/views/Marketplace/FlatDealCard.vue'
  import { flatDealsRepository } from '@/services/repository-factory.js'
  import FlatDeal from '@/services/classes/FlatDeal.js'
  import handleErrors from '@/services/handleErrors.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    components: { FlatDealCard },
    props: {
      dealId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        GTM_EVENTS,
        similarFlatDeals: [],
        isLoading: false
      }
    },
    created() {
      this.fetchSimilarFlatDeals()
    },
    methods: {
      sendGtm(event) {
        gtmPush({
          event,
          event_category: GTM_EVENTS_CATEGORY.FEATURE
        })
      },
      async fetchSimilarFlatDeals() {
        this.isLoading = true
        try {
          const response = await flatDealsRepository.similarFlatDeals(this.dealId)
          this.similarFlatDeals = response?.data?.map((deal) => new FlatDeal(deal))
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      }
    }
  }
</script>
