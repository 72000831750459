<template>
  <div v-if="site.id === null" class="d-flex flex-column justify-center align-center mt-10">
    <v-progress-circular
      width="10"
      size="83"
      color="primary"
      indeterminate
      class="pb-16"
    />
  </div>
  <div v-else-if="trafficSources.length === 0" class="d-flex justify-center align-center fill-height mt-n12">
    <div class="font-weight-bold text-subtitle-1">
      {{ $t('marketplace.no_data') }}
    </div>
  </div>
  <custom-chart v-else type="bar" :options="options" :series="series" height="160px" class="mb-n6" />
</template>

<script>
  import CustomChart from '@/components/CustomChart.vue'
  import SiteNew from '@/services/classes/SiteNew.js'

  export default {
    name: 'ChannelOverviewsChart',
    components: { CustomChart },
    props: {
      site: {
        type: SiteNew,
        required: true
      }
    },
    computed: {
      trafficSources() {
        return this.site.siteReport.similarwebTrafficSources.map(({ organicViews, paidViews }) => organicViews + paidViews)
      },
      trafficSourcesTotal() {
        return this.trafficSources.length ? this.trafficSources.reduce((a, b) => a + b) : 1
      },
      options() {
        return {
          chart: {
            height: 200,
            type: 'bar',
            toolbar: {
              show: false
            }
          },
          colors: [
            this.$vuetify.theme.themes.light['cpc-goal-color'],
            this.$vuetify.theme.themes.light['cpm-goal-color'],
            this.$vuetify.theme.themes.light['cpc-color'],
            this.$vuetify.theme.themes.light['cpa-color'],
            this.$vuetify.theme.themes.light.info,
            this.$vuetify.theme.themes.light.error
          ],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              dataLabels: {
                position: 'top'
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: (val) => `${((val * 100) / this.trafficSourcesTotal).toFixed()}%`,
            offsetY: -20,
            style: {
              fontSize: '12px',
              fontFamily: '"Roboto", sans-serif',
              colors: [this.$vuetify.theme.themes.light.black]
            }
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: this.site.siteReport.similarwebTrafficSources.map(({ type }) => type),
            labels: {
              style: {
                colors: this.site.siteReport.similarwebTrafficSources.map(() => this.$vuetify.theme.themes.light['secondary-darken']),
                fontSize: '12px',
                fontFamily: '"Roboto", sans-serif'
              }
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            labels: {
              show: false,
              formatter: (val) => `${val}`
            }
          },
          grid: {
            show: false
          }
        }
      },
      series() {
        return [{
          name: 'Value',
          data: this.trafficSources
        }]
      }
    }
  }
</script>
