<template>
  <v-card elevation="0" class="box-shadow">
    <v-card-title class="d-block text-center text-no-wrap pb-6 text-truncate">
      <v-icon size="15" color="primary">
        {{ titleIcon }}
      </v-icon>
      <span class="text-caption text-lg-subtitle-2 secondary-darken--text ml-2 ">{{ title }}</span>
      <v-divider class="mt-2" />
    </v-card-title>
    <v-card-text class="black--text fill-height">
      <slot>
        <div class="d-flex justify-center text-caption text-lg-body-1 font-weight-bold pb-lg-3">
          <span class="text-truncate">{{ body }}</span>
        </div>
      </slot>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'ParameterCard',
    props: {
      title: {
        type: String,
        default: ''
      },
      titleIcon: {
        type: String,
        default: ''
      },
      body: {
        type: [String, Number],
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .box-shadow {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08)!important;
 }
</style>
