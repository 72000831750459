<template>
  <v-card elevation="0" class="box-shadow pa-3">
    <div class="d-flex justify-center align-center secondary-lighten rounded fill-height pa-3">
      <v-img
        v-if="logoSrc"
        :src="logoSrc"
        contain
        width="100%"
        min-height="80"
        max-height="80"
        max-width="200"
      />
      <v-icon v-else size="100%" color="black" class="max-height-55">
        $earth
      </v-icon>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'LogoCard',
    props: {
      logoSrc: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .box-shadow {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08)!important;
  }
  .max-height-55 {
    max-height: 55px;
  }
</style>
