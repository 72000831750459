<template>
  <div v-if="site.id === null" class="d-flex flex-column justify-center align-center mt-10">
    <v-progress-circular
      width="10"
      size="83"
      color="primary"
      indeterminate
      class="pb-16"
    />
  </div>
  <div v-else-if="isData" class="d-flex justify-center align-center fill-height mt-n12">
    <div class="font-weight-bold text-subtitle-1">
      {{ $t('marketplace.no_data') }}
    </div>
  </div>
  <custom-chart v-else type="donut" :options="options" :series="series" height="200px" />
</template>

<script>
  import CustomChart from '@/components/CustomChart.vue'
  import SiteNew from '@/services/classes/SiteNew.js'

  export default {
    name: 'GenderChart',
    components: { CustomChart },
    props: {
      site: {
        type: SiteNew,
        required: true
      }
    },
    computed: {
      isData() {
        return this.site.siteReport.similarwebMale === null
          || this.site.siteReport.similarwebFemale === null
      },
      gendersToPercents() {
        return {
          male: (this.site.siteReport.similarwebMale * 100).toFixed(),
          female: (this.site.siteReport.similarwebFemale * 100).toFixed()
        }
      },
      isDesktop() {
        return this.$vuetify.breakpoint.lgAndUp
      },
      options() {
        return {
          dataLabels: {
            enabled: false
          },
          legend: {
            position: 'bottom',
            fontSize: this.isDesktop ? '14px' : '12px',
            fontFamily: '"Roboto", sans-serif',
            labels: {
              colors: this.$vuetify.theme.themes.light.black
            },
            markers: {
              width: 10,
              height: 10
            }
          },
          labels: [
            `<b>${this.$t('marketplace.male')}</b> - ${this.gendersToPercents.male}%`,
            `<b>${this.$t('marketplace.female')}</b> - ${this.gendersToPercents.female}%`
          ],
          plotOptions: {
            pie: {
              expandOnClick: false
            }
          },
          tooltip: {
            style: {
              fontSize: '12px',
              fontFamily: '"Roboto", sans-serif'
            },
            y: {
              formatter: (a) => `${(a * 100).toFixed()}%`,
              title: {
                formatter: (seriesName) => `${seriesName.split(' -')[0]}:`
              }
            }
          },
          colors: [
            this.$vuetify.theme.themes.light['cpm-goal-color'],
            this.$vuetify.theme.themes.light.error
          ]
        }
      },
      series() {
        return [+this.site.siteReport.similarwebMale, +this.site.siteReport.similarwebFemale]
      }

    }
  }
</script>
